import { CreditSubscription, ExpiringCredits } from "@custom-types/credit-types";
import { SdbCompany } from "@custom-types/sdb-company-types";
import { AdminAPITypes, CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { getDaysUntilExpiration } from "@utils/date-utils";

/** The number of days before the expiration date of a subscription to show a warning */
export const EXPIRATION_WARNING_DAYS = 14;

/** This is the tag added to all companies that were migrated from Sphere Legacy */
export const SPHERE_LEGACY_MIGRATED_TAG = "sys:sphere-migration";

/**
 * Gets the url to open the provided company in the Sphere Dashboard.
 *
 * If the current host is localhost, it will return the local Sphere Dashboard url. Else it will
 * return the provided workspace url.
 */
function getCompanyUrl(workspaceUrl: string, companyId: string): string {
  if (window.location.host.includes("localhost")) {
    return `${window.location.origin}/${companyId}`;
  }

  return workspaceUrl;
}

/**
 * Returns the ID of the related company from the provided workspace url
 */
export function getWorkspaceId(
  workspace: SphereDashboardAPITypes.IWorkspace
): string {
  let id;
  if (workspace.type === CoreAPITypes.EWorkspaceType.company) {
    id = workspace.url.split("/").pop();
  }
  return id ?? "";
}

/**
 * Receives both the companies and workspaces and return back a unique SdbCompany array type that covers both
 */
export function generateSdbCompanies(
  companies: SphereDashboardAPITypes.ICompanyWithRoleDetails[],
  workspaces: SphereDashboardAPITypes.IWorkspace[]
): SdbCompany[] {
  return workspaces.map((workspace) => {
    if (workspace.type === CoreAPITypes.EWorkspaceType.admin) {
      return {
        apiUrl: workspace.apiUrl,
        domain: workspace.domain,
        id: workspace.name,
        logoUrl: workspace.icon,
        name: workspace.name,
        role: {
          workspace: workspace.role,
        },
        type: workspace.type,
        url: workspace.url,
        tags: [],
        isSphereLegacyMigrated: false,
      };
    }

    // Find the related company of the workspace
    const company = companies.find(
      (company) =>
        company.id === getWorkspaceId(workspace) &&
        company.name === workspace.name
    );

    if (!company) {
      throw new Error(
        "The workspace is neither related to an admin workspace or a company"
      );
    }

    // Since this is a new property, add a default in case it does not exist for all workspaces
    const tags = company.tags ?? [];

    return {
      apiUrl: workspace.apiUrl,
      domain: company.domain,
      id: company.id,
      logoUrl: company.logoUrl,
      name: company.name,
      role: {
        company: company.role,
        workspace: workspace.role,
      },
      type: workspace.type,
      url: getCompanyUrl(workspace.url, company.id),

      tags,
      isSphereLegacyMigrated: tags.includes(SPHERE_LEGACY_MIGRATED_TAG),
    };
  });
}

/**
 * Custom function to sort workspaces by name, admin workspaces are always first.
 */
export function sortWorkspaces(
  workspaceA: SdbCompany,
  workspaceB: SdbCompany
): number {
  if (
    workspaceA.type === CoreAPITypes.EWorkspaceType.admin &&
    !(workspaceB.type === CoreAPITypes.EWorkspaceType.admin)
  ) {
    return -1;
  }
  if (
    !(workspaceA.type === CoreAPITypes.EWorkspaceType.admin) &&
    workspaceB.type === CoreAPITypes.EWorkspaceType.admin
  ) {
    return 1;
  }
  return workspaceA.name.localeCompare(workspaceB.name);
}

/** 
 * Helper function to process subscription constraints.
 * Calculating available, used, and maximum credits,
 * along with expiration date and ID for the credit constraint types.
 */
export function processSubscriptionConstraints(
  subscription: SphereDashboardAPITypes.IGetCompanySubscriptionResponse
): CreditSubscription[] {
  return subscription.constraints
    .filter(
      (constraint) =>
        constraint && constraint.type === AdminAPITypes.EConstraintType.credits
    )
    .map((constraint) => {
      const { max, current: usedCredits } = constraint.data;
      const current = usedCredits ?? 0;
      const availableCredit = max - current;

      return {
        availableCredit,
        max,
        current,
        expiresAt: subscription.endDate,
        startDate: subscription.startDate,
        // The backend doesn’t provide a unique identifier for subscription entities,
        // but the frontend requires one (e.g., for table rendering). 
        // Hence, we generate a temporary ID in the client to properly manage them.
        uiTemporaryId: crypto.randomUUID(),
      };
    });
  }

/** 
  * Determine if credits are within the specified expiration warning period.
  * @param subscriptionDetails - The details of the subscription to check.
  * @returns {boolean} - True if the subscription is expiring soon, false otherwise. 
 */
export function isCreditsNearExpiry(expiringCredits: ExpiringCredits | undefined): boolean {
  if (!expiringCredits) {
    return false;
  }
  return getDaysUntilExpiration(expiringCredits.expiresAt) <= EXPIRATION_WARNING_DAYS;
};

