import { useDateTime } from "@hooks/use-date-time";
import { Stack, SvgIcon, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import WarningIcon from "@assets/icons/new/exclamation-mark-circle-fill_24px.svg?react";
import { CreditViewMode, ExpiringCredits } from "@custom-types/credit-types";
import { CreditsDialog } from "@pages/settings/subscription-settings/credits/credits-dialog";

interface CreditSubscriptionsProps {
  /** The first credits that are expiring soon */
  earliestExpiringCredits : ExpiringCredits | undefined;

  /** A flag indicating whether the subscription is set to expire within the next 14 days */
  isExpiringSoon: boolean;
}

/** 
 * Shows the first upcoming expiration date among the available credit subscriptions list.
 * It provides a button to view all credit subscriptions in the form of a dialog.
 */
export function CreditSubscriptions({ earliestExpiringCredits, isExpiringSoon }: CreditSubscriptionsProps): JSX.Element {
  const { formatDate } = useDateTime();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Typography
        sx={{
          gap:1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {isExpiringSoon && 
          <SvgIcon 
            component={WarningIcon} 
            sx={{ 
              width: "20px", 
              height: "20px", 
              color: sphereColors.yellow600, 
            }} 
          />
        }
        {earliestExpiringCredits ? formatDate(earliestExpiringCredits?.expiresAt) : "-" }
      </Typography>

      <CreditsDialog buttonLabel="View purchases" initialViewMode={CreditViewMode.purchases}/>
    </Stack>
  );
}