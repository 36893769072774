import { CREDITS_DISPLAY_NAME } from "@src/constants/credit-constants";
import { APITypes } from "@stellar/api-logic";
import { capitalizeFirstLetter } from "@utils/string-utils";

/**
 * TODO: Move some types and interfaces to api-logic when properties are finalized
 * Ticket: https://faro01.atlassian.net/browse/ST-2874
 */

/** List of the transaction sources, From which application a request has been mane to create a a transaction */
export enum TransactionSources {
  sphereDashboard = "SphereDashboard",
  webEditor = "WebEditor",
  sphereViewer = "SphereViewer",
  coreApi = "core-api",
}

/** Information about a transaction source application */
interface CreditTransactionSource {
  /** Name of a transaction source application */
  type: TransactionSources;

  /** Version of the source application */
  version: string;
}

/** List of actions for a transaction */
export enum TransactionActions {
  creditConsumption = "CreditConsumption",
  creditPurchase = "CreditPurchase",
}

/** List of reasons for credit consumption */
export enum ConsumptionReason {
  floorplanGeneration = "floorplan-generator",
  default = "",
}

export const ConsumptionDisplayReason: { [key in ConsumptionReason]: string } =
  {
    [ConsumptionReason.floorplanGeneration]: `${capitalizeFirstLetter(
      CREDITS_DISPLAY_NAME
    )} spent on floor plan generation.`,
    [ConsumptionReason.default]: "",
  };

/** Information about a transaction action, Purpose of the transaction */
interface CreditTransactionAction {
  /** Predefined type of the action i.e: "ConsumeCredits" */
  type: string;

  /** Predefined action category of a transaction, can be used to determine the purpose of the transaction  */
  category: TransactionActions;

  /** Additional information about a transaction action */
  data: {
    /** Amount of the token processed in a transaction */
    creditAmount: number;

    /** Reason for the consumption of the token */
    consumptionReason?: ConsumptionReason;

    // TODO: Add properties related to add credits when needed
    // https://faro01.atlassian.net/browse/ST-2957
  };

  /**
   * Tags that can be used to filter and categorize actions.
   * Read-only - populated by the service based on the action id and category pair.
   */
  tags: string[];
}

/** Additional information about a transaction */
interface TransactionContext {
  /** The id of the user who triggered the request to create a credit transaction */
  userId?: APITypes.UserId | null;

  /** Used as fallback and initial ui value until user id resolved */
  userEmail?: string | null;

  /** The user for whom the event was created. */
  onBehalfOfUser?: APITypes.UserIdentity | null;

  /** The id of the company for which the transaction has been processed */
  companyId: APITypes.CompanyId;

  /** The id of the project for which the transaction has been processed */
  projectId?: APITypes.ProjectId | null;

  /** The id of any other activity events, can be grouped multiple activity */
  workflowId?: string | null;

  /** The service that created the event. */
  serviceId?: string | null;
}

export interface CreditTransaction {
  /** The id of the transaction */
  id: APITypes.UUID;

  /** Timestamp of transaction creation */
  timestamp: string;

  /** Information about the source application from where the transaction is created */
  source: CreditTransactionSource;

  /** Information about a transaction action, Purpose of the transaction */
  action: CreditTransactionAction;

  /** Additional information about the transaction */
  context: TransactionContext;

  result: {
    type:
      | "CreditPurchased"
      | "CreditConsumed"
      | "CreditConsumptionRejected"
      | "CreditConsumptionFailed";
    /** Additional information about context data */
    data: {
      /** The subscription(s) where the tokens were consumed from */
      subscriptionIds: string[];

      /**
       * The reason for the rejection of the credit consumption
       * This is only available when the type is "CreditConsumptionRejected"
       */
      rejectionReason?: string;

      /**
       * The reason for the failure of the credit consumption
       * This is only available when the type is "CreditConsumptionFailed"
       */
      failureReason?: string;

      /**
       * The details of the failure of the credit consumption
       * This is only available when the type is "CreditConsumptionFailed"
       */
      failureDetails?: string | null;
    };
  };

  /**
   * The visibility of the action. By default, only external actions are visible to clients.
   * Read-only - populated by the service based on the action id and category pair.
   */
  visibility: "Internal" | "External";
}

export interface CreditSubscription {
  /**
   * The available credit of a subscription
   * This is the difference between the maximum credit and the used credit.
   */
  availableCredit: number;

  /** The expiration date of a subscription */
  expiresAt: APITypes.Iso8601Date;

  /** The start date of a subscription */
  startDate: APITypes.Iso8601Date;

  /** The maximum credit of a subscription */
  max: number;

  /** The amount of credit used so far */
  current: number;

  /**
   * A unique identifier to identify a credit subscription object
   * This is used to identify the object in the UI and is not coming from the backend.
   */
  uiTemporaryId: APITypes.UUID;
}

export interface ExpiringCredits {
 /** 
   * The sum of available credits from all subscriptions that share the same expiration date.
  */
  totalCredits: number;

  /** The expiration date */ 
  expiresAt: string;
}

/** The available view modes for the credit dialog. */
export enum CreditViewMode {
  transaction = "Transactions",
  purchases = "Purchases",
}

export enum CreditSubscriptionStatus {
  /** Means the credits remaining are the same as the purchased credits */
  active = "Active",

  /** Means the credits remaining are more than 0 but less than the purchased credits */
  activeInUse = "Active - In Use",

  /** The expiration date is later than the current date but the credits remaining is 0 */
  used = "Used",

  /** The expiration date is later than the current date */
  expired = "Expired",
}
